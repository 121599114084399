import apiHelperForBank from "@/http/apiHelperForBank";
export default {
  // login
  login(body) {
    return apiHelperForBank.post(`/login`, body);
  },

  // updateUser
  updateUser(body, userId) {
    return apiHelperForBank.put(`/users/${userId}`, body);
  },

  // retrieveAUser
  retrieveAUser(userId) {
    return apiHelperForBank.get(`/users/${userId}`);
  },
  // retrieveAUser
  retrieveAllAUser() {
    return apiHelperForBank.get(`/users`);
  },

  // forgotPassword
  forgotPassword(body = {}) {
    return apiHelperForBank.post(`/forgot-password`, body);
  },

  // forgotPassword
  comleteStokvel(body = {}) {
    return apiHelperForBank.post(`/stokvels/compleate-stokvel`, body);
  },

  // Token
  stokvelByToken(token) {
    return apiHelperForBank.get(`/stokvels/token/${token}`);
  },

  // resetPassword
  resetPassword(body = {}) {
    return apiHelperForBank.post(`/reset-password`, body);
  },

  verifyUserClient(body = {}) {
    return apiHelperForBank.post(`/verify-user-client`, body);
  },

  // changePassword
  changePassword(body) {
    return apiHelperForBank.post(`/change-password`, body);
  },

  // categorizedPayment
  categorizedPayment(body) {
    return apiHelperForBank.post(`/catergorisedpayment`, body);
  },

  // getTenants
  getTenants(companyName) {
    return apiHelperForBank.get(`/public/tenants/${companyName}`);
  },

  // loginIntoTenant
  loginIntoTenant(body = {}) {
    return apiHelperForBank.post(`/tenants/auth/login`, body);
  },

  // createBankInvestment
  createBankInvestment(body = {}) {
    return apiHelperForBank.post(`/public/investments`, body);
  },

  //investment
  // getInvestment
  getInvestments() {
    return apiHelperForBank.get(`/investment`);
  },

  createLoanEligibilityMultiplier(body) {
    return apiHelperForBank.post(`/loan-eligibility-multipliers`, body);
  },

  updateLoanEligibilityMultiplier(body) {
    return apiHelperForBank.put(`/loan-eligibility-multipliers`, body);
  },

  getLoanEligibilityMultipliersByTenant(tenantIdentifier) {
    return apiHelperForBank.get(
      `public/loan-eligibility-multipliers/by-tenant/${tenantIdentifier}`
    );
  },

  // getLoanEligibilityMultipliersByTenant(tenantIdentifier) {
  //   return apiHelperForBank.get(
  //     `/loan-eligibility-multipliers/by-tenant/${tenantIdentifier}`
  //   );
  // },

  // bulk uploads
  // createBulkUpload
  createBulkUpload(body = {}) {
    return apiHelperForBank.post(`/bulkUploads`, body);
  },

  // getBulkUploads
  getBulkUploadById(id) {
    return apiHelperForBank.get(`/bulkUploads/${id}`);
  },

  // getBulkUploads
  getBulkUploads() {
    return apiHelperForBank.get(`/bulkUploads`);
  },

  // stokvelConstitution
  stokvelConstitution(tenantIdentifier) {
    return apiHelperForBank.get(
      `/public/stokvels/stokvel-details/${tenantIdentifier}`
    );
  },

  // stokvelApplicationDocuments
  stokvelApplicationDocuments() {
    return apiHelperForBank.get(`/documents`);
  },

  // stokvelApplicationDocuments
  stokvelApplicationDocumentsById(id) {
    return apiHelperForBank.get(`/documents/stokvel/${id}`);
  },

  // updateInvestment
  updateInvestment(body, investmentId) {
    return apiHelperForBank.put(`/investment/${investmentId}`, body);
  },

  // createStokvelWithDocument
  createStokvelWithDocument(body = {}) {
    return apiHelperForBank.post(`/public/stokvelwithdocument`, body);
  },

  // upload stokvel documents (id, constitution)
  updateStokvelDocument(body, documentId) {
    // return apiHelper.put(`/users/documents`, body, {});
    return apiHelperForBank.put(`/documents/${documentId}`, body);
  },

  // createEmail
  createEmail(body = {}) {
    return apiHelperForBank.post(`/emails/send-email-with-html`, body);
  },

  // createSMS
  createSMS(body = {}) {
    return apiHelperForBank.post(`/service/sms/send-sms`, body);
  },

  getStokvels() {
    return apiHelperForBank.get(`/stokvels`);
  },

  // updateStokvels() {
  //   // eslint-disable-next-line no-undef
  //   return apiHelperForBank.get(`/stokvels/${id}`, body);
  // },

  deleteStokvels(id) {
    // eslint-disable-next-line no-undef
    return apiHelperForBank.delete(`/stokvels/${id}`);
  },

  deleteTransactionts(id) {
    // eslint-disable-next-line no-undef
    return apiHelperForBank.delete(`/transactions/${id}`);
  },

  // updateStokvel
  updateStokvels(body, StokvelId) {
    return apiHelperForBank.put(`/stokvels/${StokvelId}`, body);
  },

  // getInvestment
  getTransactions() {
    return apiHelperForBank.get(`/transactions`);
  },

  // updateTransaction
  updateTransactions(body, id) {
    return apiHelperForBank.put(`/transactions/${id}`, body);
  },

  //messaging

  getMessages() {
    return apiHelperForBank.get(`/message`);
  },
  getMessageById(id) {
    return apiHelperForBank.get(`/message/${id}`);
  },

  getMessageBySenderId(id) {
    return apiHelperForBank.get(`/message/sender/${id}`);
  },
  getMessageByReceiverId(id) {
    return apiHelperForBank.get(`/message/receiver/${id}`);
  },

  createMessages(body = {}) {
    return apiHelperForBank.post(`/messages`, body);
  },
  createMessagesDocument(body = {}) {
    return apiHelperForBank.post(`/message/document`, body);
  },

  updateMessage(body, messageId) {
    return apiHelperForBank.put(`/message/${messageId}`, body);
  },

  deleteMessage(id) {
    return apiHelperForBank.delete(`/message/${id}`);
  },

  //inbox

  getInbox() {
    return apiHelperForBank.get(`/inbox`);
  },

  getInboxById(id) {
    return apiHelperForBank.get(`/inbox/${id}`);
  },
  getInboxBySenderId(id) {
    return apiHelperForBank.get(`/inbox/sender/${id}`);
  },
  getInboxByReceiverId(id) {
    return apiHelperForBank.get(`/inbox/receiver/${id}`);
  },

  createInbox(body = {}) {
    return apiHelperForBank.post(`/inbox`, body);
  },

  updateInbox(body, inboxId) {
    return apiHelperForBank.put(`/sent/${inboxId}`, body);
  },

  deleteInbox(id) {
    return apiHelperForBank.delete(`/inbox/${id}`);
  },

  //sent

  getSent() {
    return apiHelperForBank.get(`/sent`);
  },

  getSentById(id) {
    return apiHelperForBank.get(`/sent/${id}`);
  },
  getSentBySenderId(id) {
    return apiHelperForBank.get(`/sent/sender/${id}`);
  },
  getSentByReceiverId(id) {
    return apiHelperForBank.get(`/sent/receiver/${id}`);
  },

  createSent(body = {}) {
    return apiHelperForBank.post(`/sent`, body);
  },

  updateSent(body, sentId) {
    return apiHelperForBank.put(`/sent/${sentId}`, body);
  },

  deleteSent(id) {
    return apiHelperForBank.delete(`/sent/${id}`);
  },

  //Document
  getDocument() {
    return apiHelperForBank.get(`/emailDocuments`);
  },

  getDocumentById(id) {
    return apiHelperForBank.get(`/emailDocuments/${id}`);
  },
  getDocumentBySenderId(id) {
    return apiHelperForBank.get(`/emailDocuments/sender/${id}`);
  },
  getDocumentByReceiverId(id) {
    return apiHelperForBank.get(`/emailDocuments/receiver/${id}`);
  },

  createDocument(body = {}) {
    return apiHelperForBank.post(`/emailDocuments`, body);
  },

  updateDocument(body, sentId) {
    return apiHelperForBank.put(`/emailDocuments/${sentId}`, body);
  },

  deleteDocument(id) {
    return apiHelperForBank.delete(`/emailDocuments/${id}`);
  },

  //Reply
  getReply() {
    return apiHelperForBank.get(`/reply`);
  },

  getReplyById(id) {
    return apiHelperForBank.get(`/reply/${id}`);
  },
  // getReplyBySenderId(id) {
  //   return apiHelperForBank.get(`/reply/sender/${id}`);
  // },
  // getReplyByReceiverId(id) {
  //   return apiHelperForBank.get(`/reply/receiver/${id}`);
  // },

  createReply(body = {}) {
    return apiHelperForBank.post(`/reply`, body);
  },

  updateReply(body, sentId) {
    return apiHelperForBank.put(`/reply/${sentId}`, body);
  },

  deleteReply(id) {
    return apiHelperForBank.delete(`/reply/${id}`);
  },

  //CC
  getCC() {
    return apiHelperForBank.get(`/cc`);
  },

  getCCById(id) {
    return apiHelperForBank.get(`/cc/${id}`);
  },
  getCCBySenderId(id) {
    return apiHelperForBank.get(`/cc/sender/${id}`);
  },
  getCCByReceiverId(id) {
    return apiHelperForBank.get(`/cc/receiver/${id}`);
  },

  createCC(body = {}) {
    return apiHelperForBank.post(`/cc`, body);
  },

  updateCC(body, sentId) {
    return apiHelperForBank.put(`/cc/${sentId}`, body);
  },

  deleteCC(id) {
    return apiHelperForBank.delete(`/cc/${id}`);
  },

  //finclude

  getImageById(id) {
    return apiHelperForBank.get(`/retrieve_image/${id}`);
  },

  createObjectList(body = {}) {
    return apiHelperForBank.post(`/objectList`, body);
  },

  createAirtimeData(body = {}) {
    return apiHelperForBank.post(`/airtimeData`, body);
  },

  createMobilePay(body = {}) {
    return apiHelperForBank.post(`/mobilePay`, body);
  },

  createPrepaidUtility(body = {}) {
    return apiHelperForBank.post(`/prepaidUtility`, body);
  },

  createBillPayment(body = {}) {
    return apiHelperForBank.post(`/billPayment`, body);
  },

  createPrepaidUtilitySale(body = {}) {
    return apiHelperForBank.post(`/prepaidUtilitySale`, body);
  },

  createFincludeTransactionst(body = {}) {
    return apiHelperForBank.post(`/fincludeTransactions`, body);
  },

  getFincludeTransactions() {
    return apiHelperForBank.get(`/fincludeTransactions`);
  },
};
