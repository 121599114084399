import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const loanApi = {
  namespaced: true,

  state: {
    // loanId
    loanId: 0,

    // loan
    loan: {},

    // totalLoans
    totalLoans: 0,

    // totalLoans
    totalLoansValue: 0,

    // loans
    loans: [],

    // showDialog
    showDialog: false,

    superAdminNumLoans: 0,
  },

  mutations: {
    // setLoanId
    setLoanId(state, payload) {
      state.loanId = payload;
    },

    setSuperAdminNumLoans(state, payload) {
      state.superAdminNumLoans = payload;
    },

    // setLoan
    setLoan(state, payload) {
      // loanId
      if ("id" in payload) {
        state.loanId = payload.id;
      }

      state.loan = payload;
    },

    // setTotalLoans
    setTotalLoans(state, payload) {
      state.totalLoans = payload;
    },

    // setTotalLoans
    setTotalLoansValue(state, payload) {
      state.totalLoansValue = payload;
    },

    // setLoans
    setLoans(state, payload) {
      state.loans = payload;
    },

    // ShowDialog
    setShowDialog(state, payload) {
      state.showDialog = payload;
    },

    // resetState
    resetState(state) {
      // loanId
      state.loanId = 0;

      // loan
      state.loan = {};

      // totalLoans
      state.totalLoans = 0;

      // totalLoansValue
      state.totalLoansValue = 0;

      // loans
      state.loans = [];

      // showDialog
      state.showDialog = false;
    },
  },

  actions: {
    // getSelfLoanWithAssociationsReq
    async getSelfLoanWithAssociationsReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", false, { root: true });

      try {
        // getLoanRes
        const getLoanRes = await apiHelperFactory.getSelfLoan(payload.loanId, {
          associations: "repaymentSchedule,transactions",
        });

        // setLoan
        commit("setLoan", getLoanRes.data);

        return getLoanRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getLoanWithAssociationsReq
    async getLoanWithAssociationsReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", false, { root: true });

      try {
        // getLoanRes
        const getLoanRes = await apiHelperFactory.getLoanWithAssociations(
          payload.loanId,
          {
            associations: "repaymentSchedule,transactions",
          }
        );

        // setLoan
        commit("setLoan", getLoanRes.data);

        return getLoanRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getLoansReq
    async getLoansReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // offset
        if ("offset" in payload) {
          params.offset = payload.offset;
        }

        // limit
        if ("limit" in payload) {
          params.limit = payload.limit;
        }

        // orderBy
        if ("orderBy" in payload) {
          params.orderBy = payload.orderBy;
        }

        // sortOrder
        if ("sortOrder" in payload) {
          params.sortOrder = payload.sortOrder;
        }

        // conditions
        let conditions = [];

        // clientId
        if ("clientId" in payload) {
          conditions.push(`l.client_id IN (${payload.clientId})`);
        }

        commit("setTotalLoansValue", 0);

        // loanStatusId
        if ("loanStatusId" in payload) {
          conditions.push(`l.loan_status_id IN (${payload.loanStatusId})`);
        }

        // startDate and endDate
        if ("startDate" in payload && "endDate" in payload) {
          conditions.push(
            `l.submittedon_date BETWEEN '${payload.startDate}' AND '${payload.endDate}'`
          );
        }

        // set sqlSearch
        if (conditions.length > 0) {
          params.sqlSearch = conditions.join(" AND ");
        }

        // getLoansRes
        const getLoansRes = await apiHelperFactory.getLoans(params, false);

        // totalFilteredRecords
        if ("totalFilteredRecords" in getLoansRes.data) {
          // setTotalLoans
          commit("setTotalLoans", getLoansRes.data.totalFilteredRecords);
        }

        // pageItems
        if ("pageItems" in getLoansRes.data) {
          // setLoans
          commit("setLoans", getLoansRes.data.pageItems);

          let total = 0;

          getLoansRes.data.pageItems.forEach((loan) => {
            total += loan.approvedPrincipal;

            // setTotalLoansValue
            commit("setTotalLoansValue", total);
          });
        }

        return getLoansRes;
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getLoanReq
    async getLoanReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", false, { root: true });

      try {
        // getLoanRes
        const getLoanRes = await apiHelperFactory.getLoan(payload.loanId);

        // setLoan
        commit("setLoan", getLoanRes.data);

        return getLoanRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // withdrawnLoanByApplicantReq
    async withdrawnLoanByApplicantReq({ commit }, { payload, loanId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.withdrawnLoanByApplican(payload, loanId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // disburseLoanApplicationReq
    async disburseLoanApplicationReq({ commit }, { payload, loanId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("setUseAppToken", true);

      try {
        return await apiHelperFactory.disburseLoanApplication(payload, loanId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("setUseAppToken", false);

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // makeARepaymentReq
    async makeARepaymentReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.makeARepayment(
          payload.body,
          payload.loanId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getLoanId
    getLoanId(state) {
      return state.loanId;
    },

    // getLoan
    getLoan(state) {
      return state.loan;
    },

    getSuperAdminNumLoans(state) {
      return state.superAdminNumLoans;
    },

    // getLoan
    getPendingLoan(state) {
      let sum = 0;
      let data = state.loans;
      data.forEach((item) => {
        if (item.status.pendingApproval) {
          sum += item.principal;
        }
      });

      return sum;
    },

    // getTotalLoans
    getTotalLoans(state) {
      return state.totalLoans;
    },

    // getTotalLoans
    getTotalLoansValue(state) {
      return state.totalLoansValue;
    },

    // getLoans
    // getLoans(state) {
    //   const totalAmount = state.loans.reduce(
    //     (sum, loan) => sum + loan.totalOutstanding,
    //     0
    //   );

    //   return totalAmount;
    // },

    // getLoans
    getLoans(state) {
      return state.loans;
    },

    getLoansTotalAmount(state) {
      return state.loans;
    },

    // getShowDialog
    getShowDialog(state) {
      return state.showDialog;
    },

    // totalOutstandingForActiveLoans
    totalOutstandingForActiveLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.active) {
          return sum + (loan.summary.totalOutstanding || 0);
        }
        return sum;
      }, 0);
    },
    // totalOutstandingForActiveLoans
    totalPaidForActiveLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.active) {
          return sum + (loan.summary.totalRepayment || 0);
        }
        return sum;
      }, 0);
    },
    totalNoPaidForActiveLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.active) {
          return sum + 1;
        }
        return sum;
      }, 0);
    },
    totalPaidForApproveLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.pendingApproval) {
          return sum + (loan.approvedPrincipal || 0);
        }
        return sum;
      }, 0);
    },
    totalNoPaidForApproveLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.pendingApproval) {
          return sum + 1;
        }
        return sum;
      }, 0);
    },

    getActiveAp(state) {
      return state.loans.filter((loan) => !loan.status.pendingApproval);
    },

    // get all loans with status "Pending Approval"
    getAllPendingLoans(state) {
      return state.loans.filter((loan) => loan.status.pendingApproval);
    },

    // get all loans with status "Approved"
    getAllApprovedLoans(state) {
      return state.loans.filter((loan) => loan.status.value === "Approved");
    },

    // get number of approved loans
    getApprovedLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.value === "Approved") {
          return sum + 1;
        }
        return sum;
      }, 0);
    },

    // loan is active when approved and money is dispursed
    // getActiveLoans(state) {
    //   return state.loans.reduce((sum, loan) => {
    //     if (
    //       loan.status &&
    //       loan.status.active &&
    //       loan.summary.principalDisbursed > 0
    //     ) {
    //       return sum + 1;
    //     }
    //     return sum;
    //   }, 0);
    // },
    getActiveLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.value === "Active") {
          return sum + 1;
        }
        return sum;
      }, 0);
    },

    // get rejected / declined loans
    getRejectedLoans(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.value === "Rejected") {
          return sum + 1;
        }
        return sum;
      }, 0);
    },

    totalTotalOverdue(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.active) {
          return sum + (loan.summary.totalOverdue || 0);
        }
        return sum;
      }, 0);
    },
    /* totalPrincipalDisbursed(state) {
      return state.loans.reduce((sum, loan) => {
        if (loan.status && loan.status.active) {
          return sum + (loan.summary.principalDisbursed || 0);
        }
        return sum;
      }, 0);
    }, */

    // number of loan applications made by client
    getTotalLoanApplications(state) {
      let sum = 0;

      let data = state.loans;

      // Iterate over the Loans
      data.forEach((item) => {
        // only consider loans made by current client
        if (item.clientId === state.loan.clientId) {
          if (item.status) {
            sum++;
          }
        }
      });
      return sum;
    },

    // value of a client's loans that are approved, but money is not payed out (disbursed) yet (i.e. loans do not have actualDisbursementDate)
    totalPendingLoanPayouts(state) {
      return state.loans.reduce((sum, loan) => {
        // only consider loans made by current client
        if (loan.clientId === state.loan.clientId) {
          if (
            loan.status &&
            loan.status.value === "Approved" &&
            !loan.timeline.actualDisbursementDate
          ) {
            return sum + (loan.approvedPrincipal || 0);
          }
        }

        return sum;
      }, 0);
    },

    // total value of client's outstanding loans (client owes company)
    getTotalOutstandingLoans(state) {
      return state.loans.reduce((sum, loan) => {
        // only consider loans made by current client
        if (loan.clientId === state.loan.clientId) {
          if (
            loan.status &&
            loan.summary &&
            loan.summary.principalOutstanding
          ) {
            return sum + (loan.summary.principalOutstanding || 0);
          }
        }

        return sum;
      }, 0);
    },

    // total value of client's outstanding loans in previous month
    getTotalOutstandingLoansPreviousMonth(state) {
      let sum = 0;

      let data = state.loans;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        // only consider loans requested before previous month as their outstanding payments would then be outstanding in previous month
        previousMonth = currentMonth - 2;
      }

      // Iterate over the Loans
      data.forEach((item) => {
        // only consider loans made by current client
        if (item.clientId === state.loan.clientId) {
          console.log("getTotalOutstandingLoansPreviousMonth");
          if (
            item.status &&
            item.summary &&
            item.summary.principalOutstanding
          ) {
            let [year, month] = item.timeline.submittedOnDate;

            if (year === currentYear && month <= previousMonth)
              sum += item.summary.principalOutstanding;
          }
        }
      });
      return sum;
    },

    // value of loans of client that were approved before current month, but money was not payed out yet (disbursed) up until end of previous month
    // i.e. loans were still waiting for payouts in previous month
    totalPendingLoanPayoutsPreviousMonth(state) {
      let sum = 0;

      let data = state.loans;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the Loans
      data.forEach((item) => {
        // only consider loans made by current client
        if (item.clientId === state.loan.clientId) {
          if (item.status) {
            let [yearSubmit, monthSubmit] = item.timeline.submittedOnDate;

            // if loan application was submitted before current month....
            if (yearSubmit <= currentYear && monthSubmit <= previousMonth) {
              if (!item.timeline.actualDisbursementDate)
                // and money was not disbursed yet
                sum += item.principal;
              // or money was disbursed, but it was disbursed in current month so loan was still pending payouts in previous month
              else {
                let [yearDisbursed, monthDisbursed] =
                  item.timeline.actualDisbursementDate;
                if (
                  yearDisbursed === currentYear &&
                  monthDisbursed === previousMonth
                ) {
                  sum += item.principal;
                }
              }
            }
          }
        }
      });
      return sum;
    },

    totalPrincipalDisbursed(state) {
      return state.loans.reduce((sum, loan) => {
        return sum + (loan.summary.principalDisbursed || 0);
      }, 0);
    },

    totalPrincipalExpected(state) {
      return state.loans.reduce((sum, loan) => {
        return sum + (loan.summary.totalPrincipalExpected || 0);
      }, 0);
    },

    // get Value of Loan Applications for Approval in Previous Month
    getLoanApplicationsForApprovalValuePreviousMonth(state) {
      let sum = 0;

      let data = state.loans;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the Loans
      data.forEach((item) => {
        if (
          // loans that need to be approved and were submitted in the previous month
          item.status.pendingApproval
        ) {
          let [year, month] = item.timeline.submittedOnDate;

          if (year === currentYear && month === previousMonth)
            sum += item.principal;
        }
      });
      return sum;
    },

    // get Number of loan Applications for Approval in Previous Month
    getNumberLoanApplicationsForApprovalPreviousMonth(state) {
      let sum = 0;

      let data = state.loans;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the Loans
      data.forEach((item) => {
        if (
          // loans that need to be approved and were submitted in the previous month
          item.status.pendingApproval
        ) {
          let [year, month] = item.timeline.submittedOnDate;

          if (year === currentYear && month === previousMonth) sum += 1;
        }
      });
      return sum;
    },

    // get Number of loan payments made in Previous Month
    getNumberLoanPaymentsMadePreviousMonth(state) {
      let sum = 0;

      let data = state.loans;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the Loans
      data.forEach((item) => {
        if (
          // loans are active if money is disbursed (if payments were made to members)
          item.timeline.actualDisbursementDate
        ) {
          let [year, month] = item.timeline.actualDisbursementDate;

          if (year === currentYear && month === previousMonth) sum += 1;
        }
      });
      return sum;
    },

    // get number of all applications made by client in previous month (including rejected/closed)
    getTotalLoanApplicationsPreviousMonth(state) {
      let sum = 0;

      let data = state.loans;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the Loans
      data.forEach((item) => {
        // only consider loans made by current client
        if (item.clientId === state.loan.clientId) {
          if (item.status) {
            let [year, month] = item.timeline.submittedOnDate;

            if (year === currentYear && month === previousMonth) sum += 1;
          }
        }
      });
      return sum;
    },

    // get value of all applications made by client in previous month (including rejected/closed)
    getValueLoanApplicationsPreviousMonth(state) {
      let sum = 0;

      let data = state.loans;

      const currentDate = new Date(); // Get the current date
      let currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
      let previousMonth = 0;

      // if current month is January, December of the previous year should be used
      if (currentMonth === 1) {
        currentYear = currentYear - 1;
        previousMonth = 12;
      } else {
        previousMonth = currentMonth - 1;
      }

      // Iterate over the Loans
      data.forEach((item) => {
        // only consider loans made by current client
        if (item.clientId === state.loan.clientId) {
          if (item.status) {
            let [year, month] = item.timeline.submittedOnDate;
            // if client was onboarded in previous month, increase sum
            if (year === currentYear && month === previousMonth)
              sum += item.principal;
          }
        }
      });
      return sum;
    },

    monthlyContribution(state) {
      const transactions = state.loans;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.submittedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}-${month}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed

        const resultArray = [];

        for (let i = 11; i >= 0; i--) {
          const year = currentMonth - i > 0 ? currentYear : currentYear - 1;
          const month = (currentMonth - i + 12) % 12 || 12;

          const key = `${year}-${month}`;

          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray;
    },

    yearlyContribution(state) {
      const transactions = state.loans;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.submittedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const resultArray = [];

        for (let i = 0; i <= 7; i++) {
          const year = currentYear - i;
          const key = `${year}`;
          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray.reverse();
    },

    monthlyContributionTwo(state) {
      const transactions = state.loans;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.submittedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}-${month}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed

        const resultArray = [];

        for (let i = 11; i >= 0; i--) {
          const year = currentMonth - i > 0 ? currentYear : currentYear - 1;
          const month = (currentMonth - i + 12) % 12 || 12;

          const key = `${year}-${month}`;

          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray;
    },

    yearlyContributionTwo(state) {
      const transactions = state.loans;

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.timeline.submittedOnDate;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + 1;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const resultArray = [];

        for (let i = 0; i <= 7; i++) {
          const year = currentYear - i;
          const key = `${year}`;
          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray.reverse();
    },
  },
};

export default loanApi;
