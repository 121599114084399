import apiHelperFactory from "@/http/apiHelperFactory";
import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import helpers from "@/plugins/helpers";

const loanProductApi = {
  namespaced: true,

  state: {
    // loanProductId
    loanProductId: 0,

    // loanProductId
    loanId: 0,

    // loanProduct
    loanProduct: {},

    // loanProducts
    loanProducts: [],

    loanMultipliers: [],

    // selectedLoanProductFullDetails
    selectedLoanProductFullDetails: {},

    // selectedLoanProductId
    selectedLoanProductId: 1,

    //index
    index: 0,
  },

  mutations: {
    // setLoanProductId
    setLoanProductId(state, payload) {
      state.loanProductId = payload;
    },
    // setLoanProductId
    setSelectedLoanProductId(state, payload) {
      state.selectedLoanProductId = payload;
    },
    //setIndex
    setIndex(state, payload) {
      state.index = payload;
    },
    //setIndex
    setLoanId(state, payload) {
      state.loanId = payload;
    },
    // setLoanProduct
    setLoanProduct(state, payload) {
      state.loanProduct = payload;
    },

    // setSelectedLoanProductFullDetailsnProduct
    setSelectedLoanProductFullDetails(state, payload) {
      state.selectedLoanProductFullDetails = payload;
    },

    // setLoanProducts
    setLoanProducts(state, payload) {
      state.loanProducts = payload;
    },

    setLoanMultipliers(state, payload) {
      state.loanMultipliers = payload;
    },

    // setloanProductId
    setLoansProductId(state, id) {
      state.loanProductId = id;
    },

    // resetState
    resetState(state) {
      // loanProductId
      state.loanProductId = 0;

      // loanProduct
      state.loanProduct = {};

      // loanProducts
      state.loanProducts = [];

      state.loanMultipliers = [];

      // selectedLoanProductFullDetails
      state.selectedLoanProductFullDetails = {};

      // selectedLoanProductId
      state.selectedLoanProductId = 1;

      //index
      state.index = 0;
    },
  },

  actions: {
    // getLoanProductsReq
    async getLoanProductsReq({ commit, dispatch }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getLoanProductsRes
        const getLoanProductsRes = await apiHelperFactory.getLoanProducts();

        // setLoanProducts
        commit("setLoanProducts", getLoanProductsRes.data);

        // setDefaultData
        // dispatch("setDefaultData");

        return getLoanProductsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createLoanProductReq
    async createLoanProductReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createLoanProduct(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    async createLoanEligibilityMultiplierReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.createLoanEligibilityMultiplier(
          payload
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateLoanProductReq
    async updateLoanProductReq({ commit }, { payload, loanProductId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        console.log("try", payload);
        return await apiHelperFactory.updateLoanProduct(payload, loanProductId);
      } catch (error) {
        console.log("catch");

        // error.response
        if (error.response) {
          let res = error.response;
          console.log(error);
          if (res.status === 400) {
            // handleError
            console.log("here 1");
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            console.log("here 2");

            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            console.log("here 3");

            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            console.log("here 4");

            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            console.log("here 5");

            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            console.log("here 6");

            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    async updateLoanEligibilityMultiplierReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        console.log("try", payload);
        return await apiHelperFactoryForBank.updateLoanEligibilityMultiplier(
          payload
        );
      } catch (error) {
        console.log("catch");

        // error.response
        if (error.response) {
          let res = error.response;
          console.log(error);
          if (res.status === 400) {
            // handleError
            console.log("here 1");
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            console.log("here 2");

            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            console.log("here 3");

            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            console.log("here 4");

            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            console.log("here 5");

            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            console.log("here 6");

            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // getLoanProductsReq
    async getLoanProductByIdReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getLoanProductByIdRes
        const getLoanProductByIdRes = await apiHelperFactory.getLoanProductById(
          payload.loanProductId
        );

        // setSelectedLoanProductFullDetails
        commit("setSelectedLoanProductFullDetails", getLoanProductByIdRes.data);

        return getLoanProductByIdRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    async getLoanEligibilityMultipliersByTenantReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        console.log("getLoanEligibilityMultipliersByTenantReq 3333");
        // getLoanProductByIdRes

        // this.store.state.auth.tenantIdentifier = "develop";
        const getLoanEligibilityMultipliersByTenant =
          await apiHelperFactoryForBank.getLoanEligibilityMultipliersByTenant(
            payload.tenantIdentifier
          );

        // this.store.state.auth.tenantIdentifier = payload.tenantIdentifier;
        console.log("getLoanEligibilityMultipliersByTenantReq 444444");
        // setSelectedLoanProductFullDetails
        commit(
          "setLoanMultipliers",
          getLoanEligibilityMultipliersByTenant.data
        );
        console.log("getLoanEligibilityMultipliersByTenantReq 55555");
        return getLoanEligibilityMultipliersByTenant;
      } catch (error) {
        console.log("getLoanEligibilityMultipliersByTenantReq 6666");
        console.log("error", error);
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // setLoanProductAndLoanProductId
    setLoanProductAndLoanProductId({ commit, state }, numberOfRepayments) {
      let product = state.loanProducts.find(
        (p) => p.numberOfRepayments === numberOfRepayments
      );

      if (product) {
        commit("setLoanProduct", product);

        if ("id" in product) {
          commit("setLoanProductId", product.id);
        }

        if ("interestRatePerPeriod" in product) {
          commit(
            "applyForALoan/setInterestRate",
            product.interestRatePerPeriod,
            { root: true }
          );
        }
      } else {
        commit("setLoanProduct", {});
        commit("setLoanProductId", 0);
        commit("applyForALoan/setInterestRate", 5, {
          root: true,
        });
      }
    },

    // setDefaultData
    setDefaultData({ commit, dispatch, getters }) {
      console.log("loan products jeremy");
      // getMinNumberOfRepayments
      const minNumberOfRepayments = getters.getMinNumberOfRepayments;

      // setMonths
      commit("applyForALoan/setMonths", minNumberOfRepayments, {
        root: true,
      });

      // setLoanProductAndLoanProductId
      dispatch("setLoanProductAndLoanProductId", minNumberOfRepayments);

      // getMinPrincipal
      const minPrincipal = getters.getMinPrincipal(minNumberOfRepayments);

      // setAmount
      commit("applyForALoan/setAmount", minPrincipal, {
        root: true,
      });
    },

    // selectLoanProductId({ commit }, id) {
    //   commit("setSelectedLoanProductId", id);
    // },
  },

  getters: {
    // getLoanProductId
    getLoanProductId(state) {
      return state.loanProductId;
    },

    // getLoanProductId
    getloanId(state) {
      return state.loanId;
    },
    // getLoanProductId
    getIndex(state) {
      return state.index;
    },

    // getLoanProductById
    getLoanProductById: (state) => (id) => {
      // loanProducts
      let loanProduct = state.loanProducts.find(
        (loanProduct) => loanProduct.id === id
      );

      return loanProduct ? loanProduct : null;
    },

    getLoanMultiplierByProductId: (state) => (productId) => {
      // loanProducts
      let loanMultiplier = state.loanMultipliers.find(
        (loanMultiplier) => loanMultiplier.loanProductId === productId
      );
      console.log("loanMultipliers", state.loanMultipliers);
      console.log("loanMultiplier", loanMultiplier);
      return loanMultiplier ? loanMultiplier : null;
    },

    // getLoanProduct
    getLoanProduct(state) {
      return state.loanProduct;
    },

    // getSelectedLoanProductFullDetails
    getSelectedLoanProductFullDetails(state) {
      return state.selectedLoanProductFullDetails;
    },

    // getLoanProducts
    getLoanProducts(state) {
      return state.loanProducts;
    },

    getLoanProductByIdReq(state) {
      return state.selectedLoanProductId;
    },

    // getMinNumberOfRepayments
    getMinNumberOfRepayments(state) {
      if (state.loanProducts.length === 0) {
        return 0;
      }

      return state.loanProducts.reduce(
        (min, p) => (p.numberOfRepayments < min ? p.numberOfRepayments : min),
        state.loanProducts[state.index].numberOfRepayments
      );
    },

    // getMaxNumberOfRepayments
    getMaxNumberOfRepayments(state) {
      if (state.loanProducts.length === 0) {
        return 0;
      }

      return state.loanProducts.reduce(
        (max, p) => (p.numberOfRepayments > max ? p.numberOfRepayments : max),
        state.loanProducts[state.index].numberOfRepayments
      );
    },

    // getMinPrincipal
    getMinPrincipal: (state) => (numberOfRepayments) => {
      console.log(numberOfRepayments);
      // let applicableProducts = state.loanProducts.filter((p) => {
      //   console.log(p.numberOfRepayments, numberOfRepayments);
      //   p.numberOfRepayments === numberOfRepayments;
      // });

      if (state.loanProducts.length === 0) {
        return 0;
      }
      const prince = state.loanProducts.reduce(
        (min, p) => (p.minPrincipal < min ? p.minPrincipal : min),
        state.loanProducts[state.index].minPrincipal
      );
      return prince;
    },

    // getMaxPrincipal
    getMaxPrincipal: (state) => (numberOfRepayments) => {
      let applicableProducts = state.loanProducts.filter(
        (p) => p.numberOfRepayments === numberOfRepayments
      );

      if (applicableProducts.length === 0) {
        return 0;
      }

      return applicableProducts.reduce(
        (max, p) => (p.maxPrincipal > max ? p.maxPrincipal : max),
        applicableProducts[0].maxPrincipal
      );
    },

    // getShortNamesForLoanProducts
    getShortNamesForLoanProducts(state) {
      return state.loanProducts.map((loanProduct) => loanProduct.shortName);
    },

    // getHighestIdForLoanProducts
    getHighestIdForLoanProducts(state) {
      // highestId
      let highestId = 0;

      for (const loanProduct of state.loanProducts) {
        if (loanProduct.id > highestId) {
          highestId = loanProduct.id;
        }
      }

      return highestId;
    },

    // getNextShortNameForLoanProduct
    getNextShortNameForLoanProduct(state, getters) {
      // highestIdForLoanProduct
      const highestIdForLoanProduct = getters.getHighestIdForLoanProducts + 1;

      return "L" + highestIdForLoanProduct.toString().padStart(3, "0");
    },
  },
};

export default loanProductApi;
