const applyForALoan = {
  namespaced: true,

  state: {
    // showApplyForALoanDialog
    showApplyForALoanDialog: false,

    // showMobileForm
    showMobileForm: false,

    // applyForALoanDialogStep
    applyForALoanDialogStep: 1,

    // amount

    amountMin: 0,
    amountInterval: 1,
    amountMax: 1,
    amount: 0,

    // months
    months: 0,
    monthsMin: 0,
    monthsInterval: 1,
    monthsMax: 1,

    // interestRate
    interestRate: 5,
    interestRateMin: 0,
    interestRateInterval: 1,
    interestRateMax: 22,

    // includeInsurance
    includeInsurance: true,

    // emiPerMonth
    emiPerMonth: 377,

    // insuranceCover
    insuranceCover: 0,

    // paymentAmount
    paymentAmount: 6800.0,

    // bank
    bank: "",

    // bankAccountType
    bankAccountType: "",

    // cardNumber
    cardNumber: "",

    // expiryMonthAndYear
    expiryMonthAndYear: "",

    // cvc
    cvc: "",

    // nameOfEmployer
    nameOfEmployer: "",

    // employeeNameAndSurname
    employeeNameAndSurname: "",

    // idNumberEmployer
    idNumberEmployer: "",

    // mobileMoneyWalletNumberBank
    mobileMoneyWalletNumberBank: "",

    // mobileMoneyServiceProvider
    mobileMoneyServiceProvider: "",

    // referenceName
    referenceName: "",

    // stopOrderDate
    stopOrderDate: "",
  },

  mutations: {
    setSelectedLoanInitial(state) {
      state.amountMin = 0;
      state.amountMax = 1;
      state.amount = state.amountMin;

      state.months = 0;
      state.monthsMin = 0;
      state.monthsMax = 1;
    },

    setSelectedLoan(state, payload) {
      state.months = payload.numberOfRepayments;
      state.amount = payload.principal;
    },
    // setShowApplyForALoanDialog
    setShowApplyForALoanDialog(state, payload) {
      state.showApplyForALoanDialog = payload;
    },

    // setShowMobileForm
    setShowMobileForm(state, payload) {
      state.showMobileForm = payload;
    },

    // setApplyForALoanDialogStep
    setApplyForALoanDialogStep(state, payload) {
      state.applyForALoanDialogStep = payload;
    },

    // setAmount
    setAmount(state, payload) {
      state.amount = payload;
    },

    // setMonths
    setMonths(state, payload) {
      state.months = payload;
    },

    // setInterestRate
    setInterestRate(state, payload) {
      state.interestRate = payload;
    },

    // setIncludeInsurance
    setIncludeInsurance(state, payload) {
      state.includeInsurance = payload;
    },

    // setEmiPerMonth
    setEmiPerMonth(state, payload) {
      state.emiPerMonth = payload;
    },

    // setInsuranceCover
    setInsuranceCover(state, payload) {
      state.insuranceCover = payload;
    },

    // setPaymentAmount
    setPaymentAmount(state, payload) {
      state.paymentAmount = payload;
    },

    // setBank
    setBank(state, payload) {
      state.bank = payload;
    },

    // setBankAccountType
    setBankAccountType(state, payload) {
      state.bankAccountType = payload;
    },

    // setCardNumber
    setCardNumber(state, payload) {
      state.cardNumber = payload;
    },

    // setExpiryMonthAndYear
    setExpiryMonthAndYear(state, payload) {
      state.expiryMonthAndYear = payload;
    },

    // setCvc
    setCvc(state, payload) {
      state.cvc = payload;
    },

    // setNameOfEmployer
    setNameOfEmployer(state, payload) {
      state.nameOfEmployer = payload;
    },

    // setEmployeeNameAndSurname
    setEmployeeNameAndSurname(state, payload) {
      state.employeeNameAndSurname = payload;
    },

    // setIdNumberEmployer
    setIdNumberEmployer(state, payload) {
      state.idNumberEmployer = payload;
    },

    // setMobileMoneyWalletNumberBank
    setMobileMoneyWalletNumberBank(state, payload) {
      state.mobileMoneyWalletNumberBank = payload;
    },

    // setMobileMoneyServiceProvider
    setMobileMoneyServiceProvider(state, payload) {
      state.mobileMoneyServiceProvider = payload;
    },

    // setReferenceName
    setReferenceName(state, payload) {
      state.referenceName = payload;
    },

    // setStopOrderDate
    setStopOrderDate(state, payload) {
      state.stopOrderDate = payload;
    },

    // clearCalculator
    clearCalculator(state) {
      // state.amount = 38000;
      // state.months = 6;
      // state.interestRate = 8;

      // amount
      state.amount = 500;

      // months
      state.months = 1;
    },

    // resetState
    resetState(state) {
      // showApplyForALoanDialog
      state.showApplyForALoanDialog = false;

      // showMobileForm
      state.showMobileForm = false;

      // applyForALoanDialogStep
      state.applyForALoanDialogStep = 1;

      // amount

      state.amountMin = 0;
      state.amountInterval = 1;
      state.amountMax = 1;
      state.amount = 0;

      // months
      state.months = 0;
      state.monthsMin = 0;
      state.monthsInterval = 1;
      state.monthsMax = 1;

      // interestRate
      state.interestRate = 5;
      state.interestRateMin = 0;
      state.interestRateInterval = 1;
      state.interestRateMax = 22;

      // includeInsurance
      state.includeInsurance = true;

      // emiPerMonth
      state.emiPerMonth = 377;

      // insuranceCover
      state.insuranceCover = 12;

      // paymentAmount
      state.paymentAmount = 6800.0;

      // bank
      state.bank = "";

      // bankAccountType
      state.bankAccountType = "";

      // cardNumber
      state.cardNumber = "";

      // expiryMonthAndYear
      state.expiryMonthAndYear = "";

      // cvc
      state.cvc = "";

      // nameOfEmployer
      state.nameOfEmployer = "";

      // employeeNameAndSurname
      state.employeeNameAndSurname = "";

      // idNumberEmployer
      state.idNumberEmployer = "";

      // mobileMoneyWalletNumberBank
      state.mobileMoneyWalletNumberBank = "";

      // mobileMoneyServiceProvider
      state.mobileMoneyServiceProvider = "";

      // referenceName
      state.referenceName = "";

      // stopOrderDate
      state.stopOrderDate = "";
    },
  },

  actions: {},

  getters: {
    // calculateEmiPerMonth
    calculateEmiPerMonth(state) {
      const monthlyInterest = state.interestRate / 100 / 12;
      const x = Math.pow(1 + monthlyInterest, state.months);
      const monthly =
        (state.amount * x * monthlyInterest) / (x - 1) + state.insuranceCover;

      return isFinite(monthly) ? monthly : 0;
    },

    // calculatePaymentAmount
    calculatePaymentAmount(state, getters) {
      return getters.calculateEmiPerMonth * state.months;
    },

    // calculateInsuranceCover
    calculateInsuranceCover(state) {
      return state.amount * 0.015;
    },

    // getShowApplyForALoanDialog
    getShowApplyForALoanDialog(state) {
      return state.showApplyForALoanDialog;
    },

    // getShowMobileForm
    getShowMobileForm(state) {
      return state.showMobileForm;
    },

    // getApplyForALoanDialogStep
    getApplyForALoanDialogStep(state) {
      return state.applyForALoanDialogStep;
    },

    // getAmount
    getAmount(state) {
      return state.amount;
    },

    // getMonths
    getMonths(state) {
      return state.months;
    },

    // getInterestRate
    getInterestRate(state) {
      return state.interestRate;
    },

    // getIncludeInsurance
    getIncludeInsurance(state) {
      return state.includeInsurance;
    },

    // getEmiPerMonth
    getEmiPerMonth(state) {
      return state.emiPerMonth;
    },

    // getInsuranceCover
    getInsuranceCover(state) {
      return state.insuranceCover;
    },

    // getPaymentAmount
    getPaymentAmount(state) {
      return state.paymentAmount;
    },

    // getBank
    getBank(state) {
      return state.bank;
    },

    // getBankAccountType
    getBankAccountType(state) {
      return state.bankAccountType;
    },

    // getCardNumber
    getCardNumber(state) {
      return state.cardNumber;
    },

    // getExpiryMonthAndYear
    getExpiryMonthAndYear(state) {
      return state.expiryMonthAndYear;
    },

    // getCvc
    getCvc(state) {
      return state.cvc;
    },

    // getNameOfEmployer
    getNameOfEmployer(state) {
      return state.nameOfEmployer;
    },

    // getEmployeeNameAndSurname
    getEmployeeNameAndSurname(state) {
      return state.employeeNameAndSurname;
    },

    // getIdNumberEmployer
    getIdNumberEmployer(state) {
      return state.idNumberEmployer;
    },

    // getMobileMoneyWalletNumberBank
    getMobileMoneyWalletNumberBank(state) {
      return state.mobileMoneyWalletNumberBank;
    },

    // getMobileMoneyServiceProvider
    getMobileMoneyServiceProvider(state) {
      return state.mobileMoneyServiceProvider;
    },

    // getReferenceName
    getReferenceName(state) {
      return state.referenceName;
    },

    // getStopOrderDate
    getStopOrderDate(state) {
      return state.stopOrderDate;
    },
  },
};

export default applyForALoan;
